'use client'
import { LazyMotion } from 'framer-motion'

const LazyMotionWrapper = ({ children }: { children: React.ReactNode }) => {
	return (
		<LazyMotion
			features={() =>
				import('lib/framer-motion/features').then((mod) => mod.default)
			}
		>
			{children}
		</LazyMotion>
	)
}

export default LazyMotionWrapper
