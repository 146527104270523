import(/* webpackMode: "eager", webpackExports: ["AlertBanner"] */ "/vercel/path0/apps/www/app/[locale]/components/alert-banner-wrapper/alert-banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/www/components/lazy-motion-wrapper/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/www/components/locale-switcher/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConsentManager","ConsentManagerButton"] */ "/vercel/path0/apps/www/components/materia/consent-manager/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/www/components/materia/prefooter-newsletter-form/style.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/www/components/materia/footer/style.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Submit"] */ "/vercel/path0/apps/www/components/materia/prefooter-newsletter-form/submit/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/www/components/materia/social-block/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/www/components/skip-link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppRouterNav"] */ "/vercel/path0/apps/www/layouts/standard/nav/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/mktg-logos/src/hashicorp/hashicorp-horizontal_on-dark.svg");
