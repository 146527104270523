'use client'

import Banner from '@hashicorp/react-alert-banner'
import { TAlertBannerProps } from '.'
import type { Products } from '@hashicorp/platform-product-meta'
import { parseUrl } from '@hashicorp/react-components/src/lib/parse-url'

export const AlertBanner = ({ data }: { data: TAlertBannerProps }) => {
	const notExpired =
		data?.expirationDate && Date.now() < Date.parse(data?.expirationDate)

	if (notExpired) {
		return (
			<Banner
				{...data}
				url={parseUrl(data.url).href}
				product={data.theme.toLowerCase() as Products}
			/>
		)
	} else {
		return null
	}
}
